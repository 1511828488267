import externalComponent from '@/utils/external-component.js'

const Sidebar = () => externalComponent('sidebar')
const Navbar = () => externalComponent('navbar')

export default {
  name: 'App',

  components: {
    Sidebar,
    Navbar
  },

  data() {
    return {
      navbar: {
        avatarId: {
          image: require('@/assets/images/profile.svg'),
          name: this.$session.get('name'),
          type: this.$session.get('role')
        },
        options: [
          // {
          //   icon: 'users',
          //   name: 'Tu perfil',
          //   emit: 'profile'
          // },
          {
            icon: 'log-out',
            name: 'Cerrar sesión',
            emit: 'logout'
          }
        ]
      },

      sidebar: {
        logo: require('@/assets/images/logo.svg'),
        items: [
          // {
          //   icon: 'home',
          //   name: 'Dashboard',
          //   route: '/dashboard'
          // },
          {
            icon: 'calendar',
            name: 'Calendario',
            route: '/calendar'
          },
          {
            icon: 'inbox',
            name: 'Reservas',
            route: '/bookings'
          },
          {
            icon: 'settings',
            name: 'Tarifario',
            route: '/tariff'
          },
          {
            icon: 'settings',
            name: 'Servicios extra',
            route: '/service'
          }
        ]
      }
    }
  },

  methods: {
    goProfile: function() {return
      this.$router.push('/profile');
    },

    logout: function() {
      this.$session.destroy();
      this.$router.push('/');
    }
  }
}
