import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

const router = new Router({
  mode: 'history',
  scrollBehavior: () => ({
    y: 0
  }),
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => {
        return import('@/views/login')
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => {
        return import('@/views/commercial/dashboard')
      },
      beforeEnter: (to, from, next) => {
        const $session = Vue.prototype.$session;

        if($session.get('role') === 'develop') {
          next({ name: 'calendar' })
        } else {
          next({ name: 'calendar' }) 
        }
      }
    },
    {
      path: '/bookings',
      name: 'bookings',
      component: () => {
        return import('@/views/commercial/bookings')
      }
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: () => {
        return import('@/views/commercial/calendar')
      }
    },
    {
      path: '/tariff',
      name: 'tariff',
      component: () => {
        return import('@/views/commercial/tariff')
      }
    },
    {
      path: '/service',
      name: 'service',
      component: () => {
        return import('@/views/commercial/service')
      }
    },
    {
      path: '/payment/:id',
      name: 'payment',
      component: () => {
        return import('@/views/commercial/payment')
      },
      props: true
    }
  ]
});

router.beforeEach((to, from, next) => {
  const $session = Vue.prototype.$session;
  if (to.name !== 'login' && to.name !== 'payment' && !$session.exists()) 
    next({ name: 'login' });
  else
    next();
});


export default router;
