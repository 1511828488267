export default function externalComponent(component) {
  try {
    // let url = 'https://api.library.welovecorner.com/' + component + '/index.umd.min.js';
    let url = (process.env.NODE_ENV === 'production')
    ? 'https://api.library.welovecorner.com/' + component + '/index.umd.min.js'
    // : 'http://localhost:5555/' + component + '/index.umd.min.js';
    
    // ? 'https://api.library.welovecorner.com/' + component + '/index.umd.min.js'
    : 'https://api.library.welovecorner.com/' + component + '/index.umd.min.js';

    let name = url.split('/').reverse()[0].match(/^(.*?)\.umd/)[1];
  
    window[name] = new Promise((resolve, reject) => {
      const script = document.createElement('script');
  
      script.async = true;
  
      script.addEventListener('load', () => {
        resolve(window[name]);
      });
  
      script.addEventListener('error', () => {
        reject(new Error(`Error loading ${url}`));
      });
  
      script.src = url;
  
      document.head.appendChild(script);
    });
  
    return window[name];
  } catch (error) {}
  // let url = 'https://api.library.welovecorner.com/' + component + '/index.umd.min.js';
  // let name = url.split('/').reverse()[0].match(/^(.*?)\.umd/)[1];

  // window[name] = new Promise((resolve, reject) => {
  //   const script = document.createElement('script');

  //   script.async = true;

  //   script.addEventListener('load', () => {
  //     resolve(window[name]);
  //   });

  //   script.addEventListener('error', () => {
  //     reject(new Error(`Error loading ${url}`));
  //   });

  //   script.src = url;

  //   document.head.appendChild(script);
  // });

  // return window[name];
}
