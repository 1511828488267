import Vue from 'vue'
import App from './views/app'
import router from './router'
import './assets/static'

import VueCryptojs from 'vue-cryptojs'
Vue.use(VueCryptojs)

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
library.add(fas)
library.add(fab)
library.add(far)

import InlineSvg from 'vue-inline-svg'
Vue.component('inline-svg', InlineSvg)

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)

import VueSession from 'vue-session'
Vue.use(VueSession)

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css';
Vue.use(VueToast, {
  queue: true,
  position: 'top',
  duration: 3000,
  dismissible: true
})

import VueGlobalVariable from 'vue-global-var'
Vue.use(VueGlobalVariable, {
  globals: {
    $color: {
      primary: '#005B74',
      secondary: '#F87C8A'
    }
  }
})

Vue.config.productionTip = true;

new Vue({
  router,
  render: function (h) {
    return h(App)
  }
}).$mount('#app');
